import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoResultsInYourArea from "../components/seo-results-in-your-area";
import SeoVideoSection from "../components/seo-video-section";
import SeoWwaSection from "../components/seo-wwa-section";
import TeamSection from "../components/team-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";

const WebDesignAgencyEastbournePage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      videos: allWpVideo(
        filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
        sort: { fields: dateGmt, order: DESC }
      ) {
        nodes {
          videoFields {
            url
            seoPage
          }
          title
        }
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      ecfImg: wpMediaItem(title: { eq: "ecf-long" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ecfsmallImg: wpMediaItem(title: { eq: "ecf-two-1" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      arrowImg: wpMediaItem(title: { eq: "arrow" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      eccImg: wpMediaItem(title: { eq: "Eastbourne-commerce-white" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ebaImg: wpMediaItem(title: { eq: "EBA-rjm" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      laptopImg: wpMediaItem(title: { eq: "webdesign-laptop" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      foehImg: wpMediaItem(title: { eq: "FOEH-page" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      OASectionImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Web Design Eastbourne" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }
      allWpPost(
        filter: { blogFields: { seoPage: { in: "Web Design Eastbourne" } } }
        sort: { fields: dateGmt, order: DESC }
      ) {
        nodes {
          slug
          excerpt
          title
          dateGmt
          blogFields {
            category
            keywords
            seoTitle
            featuredImage {
              gatsbyImage(
                width: 1920
                quality: 75
                formats: [WEBP]
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Web Design Eastbourne",
        item: {
          url: `${siteUrl}/web-design-eastbourne`,
        },
      },
    ],
  };
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/web-design-eastbourne`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <SeoHero
            page="Web Design Eastbourne"
            form
            title={`Web Design \n<span class="text-secondary">Eastbourne</span>`}
            description={
              <span>
                Professional website design that will help your Eastbourne
                business shine.
              </span>
            }
            buttons={[
              <Button
                className="px-4 py-2 bg-white border-white  w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
                variant="primary"
                href="#form"
              >
                Book a discovery call
              </Button>,
              // <Button
              // 	className="px-5 py-2 mt-4 border-white  w-100 cta-btn w-md-auto fs-5 white-link"
              // 	variant="primary"
              // 	href="#price-section"
              // >
              // 	Website pricing
              // </Button>,
            ]}
          />
        </section>

        <SeoWwaSection
          spanLg={null}
          heading="Does your Eastbourne business need a new website?"
          text={
            <div>
              <p>
                RJM Digital is a leading{" "}
                <strong>web design agency in Eastbourne</strong> that helps
                local companies build brand-new bespoke websites that look
                professional, meet your specific business needs and increase the
                number of people visiting your website. 
              </p>
              <br />
              <p>
                We ensure every website has a unique design that represents your
                brand and will be fully supported by us, all for a{" "}
                <strong>reasonable monthly price</strong>.
              </p>
            </div>
          }
          buttonText="Request a call back"
          buttonUrl="#map-section"
          imgTop={data.ecfsmallImg.gatsbyImage}
          imgTopAlt={data.ecfsmallImg.altText}
          imgBot={data.ecfImg.gatsbyImage}
          imgBotAlt={data.ecfImg.altText}
        />

        <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection
            heading="Web Design Eastbourne Reviews"
            page="Our Web Services"
          />
        </section>

        <section className="py-5 py-lg-7">
          <SeoPageLogos
            heading={
              <h3>
                We are proud to be trusted by many local Eastbourne
                <br className=" d-none d-lg-block" /> businesses and
                organisations
              </h3>
            }
            page="Web Design Eastbourne"
          />
        </section>
        <section id="map-section">
          <SeoMapSection
            heading="Discuss your web design project with us"
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.2622758441216!2d0.27970641574303434!3d50.77071147952087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df735183be68e9%3A0x51b877a5d82a3df!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1663160300414!5m2!1sen!2suk"
          />
        </section>
        <div className="bg-light-grey">
          <SeoResultsInYourArea
            heading="Rank at the top of Google in Eastbourne, East Sussex"
            text={
              <div className="text-white">
                <p>
                  You have a website but aren’t getting any leads from it. Sound
                  familiar? 
                </p>

                <p>
                  This is where{" "}
                  <Link
                    to="/seo-eastbourne"
                    className="white-link text-decoration-underline"
                  >
                    Search Engine Optimisation (SEO)
                  </Link>{" "}
                  can transform your website from a business expense to a
                  revenue-generating machine. All of our websites are designed
                  to rank highly on all major search engines such as Google and
                  Bing.
                </p>

                <p>
                  <Link
                    to="/seo-eastbourne"
                    className="white-link text-decoration-underline"
                  >
                    SEO
                  </Link>{" "}
                  is continually evolving and requires up-to-date knowledge
                  about how Google works and can be a challenge to keep on top
                  of, but RJM Digital are experts in providing businesses with
                  the know-how on ways to rank higher on Google than ever
                  before!
                </p>

                <p>
                  We are incredibly proud to be trusted by the Eastbourne
                  Chamber of Commerce to provide{" "}
                  <Link
                    to="/seo-eastbourne"
                    className="white-link text-decoration-underline"
                  >
                    SEO
                  </Link>{" "}
                  support to local businesses and help them bounce back stronger
                  after the COVID-19 pandemic.
                </p>
              </div>
            }
            buttonText="SEO Agency Eastbourne"
            buttonUrl="/seo-eastbourne"
            imgTop={data.laptopImg.gatsbyImage}
            imgTopAlt={data.laptopImg.altText}
            imgBot={data.eccImg.gatsbyImage}
            imgBotAlt={data.eccImg.altText}
            imgBot2={data.arrowImg.gatsbyImage}
            imgBot2Alt={data.arrowImg.altText}
            bottomContent={
              <Row className="g-5 bx-lg-7">
                <Col className="text-center" lg={4}>
                  <h3 className="display-4 ssp-bold text-primary">50+</h3>
                  <p className="ssp-semibold" style={{ fontSize: "100%" }}>
                    Eastbourne based
                    <br /> customers
                  </p>
                </Col>
                <Col className="text-center" lg={4}>
                  <h3 className="display-4 ssp-bold text-primary">6</h3>
                  <p className="ssp-semibold" style={{ fontSize: "100%" }}>
                    Average months for
                    <br /> Return on Investment
                  </p>
                </Col>
                <Col className="text-center" lg={4}>
                  <h3 className="display-4 ssp-bold text-primary">5+</h3>
                  <p className="ssp-semibold" style={{ fontSize: "100%" }}>
                    Years working with Eastbourne businesses
                  </p>
                </Col>
              </Row>
            }
          />
          <section className="py-5 py-lg-7 ">
            <Container>
              <Row className="g-5 gx-lg-6 gx-xl-7">
                <Col lg={{ span: 7, order: "last" }}>
                  {" "}
                  <GatsbyImage
                    className="w-100 "
                    image={data.ebaImg.gatsbyImage}
                    alt={data.ebaImg.altText}
                  />
                </Col>
                <Col lg={5}>
                  <h2 className="mb-4 text-primary fs-1">
                    Eastbourne Business Awards
                  </h2>
                  <p>
                    We were incredibly proud to be nominated by our amazing
                    customers for the{" "}
                    <strong>2022 Creative/Digital Agency of the year</strong>{" "}
                    award in the Eastbourne Business Awards. 
                  </p>
                  <p>
                    We were nominated as a result of our service, delivery and
                    creativity that we commit to every project.
                  </p>
                  <p>
                    We can’t wait to offer you the same service for your new
                    website.
                  </p>
                  <Button
                    className="px-4 py-2 mt-4  w-100 w-md-auto fs-5 white-link"
                    variant="primary"
                    as={Link}
                    to="#map-section"
                  >
                    Work with us
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
          {/* <SeoWhatWeSolve
            heading="Our Web Design Eastbourne Process"
            image={data.foehImg.gatsbyImage}
            imageAlt={data.foehImg?.altText}
            imgHeight="35rem"
            text={
              <div>
                <h3 className="text-primary fs-2 ">
                  Wordpress and GatsbyJS{" "}
                  <span className="text-light-blue">website</span>
                </h3>
                <p>
                  We build our websites using a popular open-source content
                  management system (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wordpress.org/"
                    className="black-link text-decoration-underline"
                  >
                    WordPress
                  </a>{" "}
                  websites) where you can add content, media files and data to
                  your website. We add an additional layer called{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.gatsbyjs.com/"
                    className="black-link text-decoration-underline"
                  >
                    GatsbyJS
                  </a>{" "}
                  to give your website ultra-fast loading speeds, resulting in a{" "}
                  <strong>great user experience</strong>, responsive design and
                  a higher chance of website conversions.
                </p>
                <h3 className="mt-5 text-primary fs-2">
                  Bespoke web design{" "}
                  <span className="text-light-blue">Eastbourne</span>
                </h3>
                <p>
                  We will design your bespoke website using our website mock-up
                  tools where you will get unlimited revisions and changes to
                  ensure that you get a website that you can be proud of. We
                  will <strong>never purchase off-the-shelf templates </strong>{" "}
                  to design your Eastbourne website and you can be assured that
                  our expert web designers and graphic design specialists will
                  walk you through every step of the way.
                </p>
                <p>
                  We don't just design brochure websites we can also help take
                  your bricks and mortar business online with our{" "}
                  <Link
                    className="black-link text-decoration-underline"
                    to="/e-commerce-websites"
                  >
                    e-commerce website
                  </Link>{" "}
                  services.{" "}
                  <Link
                    className="black-link text-decoration-underline"
                    to="/contact-us"
                  >
                    Get in touch
                  </Link>{" "}
                  with us today.
                </p>
              </div>
            }
          /> */}
        </div>
        {/* <div id="pricing">
					<PricingSection webDesignEastbourne />
				</div> */}
        <OASection
          buttonUrl="#map-section"
          image={data.OASectionImg.gatsbyImage}
          imageAlt={data.OASectionImg?.altText}
          imgHeight="50rem"
          heading="OUR APPROACH TO WEB DESIGN PROJECTS"
          brief="Our friendly web design team will arrange various meetings with you before we start designing to truly get to know your business and your customers and understand how you want the website to look and feel. This will then ensure a successful project from the start through to the end."
          design="Our creative team will begin designing your new website and will check-in with you throughout this process to make sure we are on track. We welcome all of your suggestions and comments during this phase and we look forward to presenting you with a final design that you are proud of."
          build="After you have signed off the design of the website we can begin to build it. We use the latest website technologies to ensure that your website is reliable, secure and gives a great user experience."
          launch="Once the website is built and tested we will launch your new website. Our support team will continually monitor and maintain your site, so you’ll never need to worry."
        />
        <section>
          <TeamSection />
        </section>
        <SeoVideoSection videos={data.videos} />

        <WhyChooseRJMSection
          experienced="Having a specialist team that knows what they are doing when it comes to web design and digital marketing is crucial when you want to stand out from the crowd. RJM Digital have over 5 years experience in the digital marketing industry and can help you wherever you need it."
          bespoke="We will never purchase off-the-shelf templates and sell them as our own. Each of our websites has a unique look and feel that represents the authenticity of your business."
          provenResults="RJM Digital loves helping local Eastbourne businesses achieve results they deserve and our customer testimonials reflect this. We always reflect on our achievements and constantly look for new ways on how we can improve."
          reliable="Throughout the whole process with RJM Digital our team will always be there for you as and when you need any help or assistance."
          transparent="RJM Digital will be crystal clear in everything that we do, regardless of any hurdles along the way. We are always available for a call or meeting to discuss any queries you might have."
          trustworthy="We are pleased to have built long-lasting relationships with over 50 customers in Eastbourne and beyond by sticking to our core values of being reliable and transparent."
        />

        <section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
          <Container>
            <Row>
              <Col className="text-center">
                {" "}
                <h2 className="mb-3 display-5 text-primary">
                  Book a discovery meeting
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your web design Eastbourne project
                </p>
              </Col>
            </Row>
            <Row id="form">
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className="px-4 py-2 mt-4  w-100 w-md-auto fs-5 white-link"
                  variant="primary"
                  as={Link}
                  to="#map-section"
                >
                  Request a call back
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 py-lg-7 ">
          <Container>
            <Row>
              <Col>
                <h2 className="mb-5 text-primary fs-1">
                  Recent Eastbourne Web Design blog articles
                </h2>
              </Col>
            </Row>
            <Row className="g-5 gx-lg-6 gx-xl-7">
              {data.allWpPost.nodes.map((blog, index) => (
                <Col lg={6}>
                  <div
                    style={{
                      overflow: "hidden",
                      borderRadius: "20px",
                      boxShadow: "0px 3px 60px #00000029",
                    }}
                  >
                    <GatsbyImage
                      image={blog.blogFields.featuredImage.gatsbyImage}
                      alt={blog.blogFields.featuredImage.altText}
                      className="w-100 seo-blog-image"
                    />
                    <div className="p-4 bg-white">
                      <h3 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
                        {blog.title}
                      </h3>

                      <Link
                        to={`/blog/${convertToSlug(blog.blogFields.category)}/${
                          blog.slug
                        }`}
                        className="med-grey-link ssp-bold"
                      >
                        READ BLOG
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <OSSection
          link1="/seo-eastbourne"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        />
      </Layout>
    </>
  );
};

export default WebDesignAgencyEastbournePage;
